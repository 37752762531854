import classes from './TopicItem.module.css';
import { useHistory } from 'react-router-dom';

const TopicItem = (props) => {
  const history = useHistory();
  const openTopicHandler = () => {
    const id = props.topic.id;
    const slug = props.topic.slug;
    history.push(`/explore/topic/${slug}?id=${id}`);
  };
  return (
    <div className={classes.card} onClick={openTopicHandler}>
      <img
        src={`${props.topic.cover_photo.urls.small}&w=1000&h=1000&fit=crop&auto=format&dpr=2`}
        alt='Cover'
        className={classes.coverimage}
      />

      <div
        className={`${classes.status} ${
          props.featured ? classes.closing : classes.open
        }`}
      >
        <i
          className={`fa-solid fa-circle fa-2xs ${classes.icon}`}
          style={{
            color: `${props.featured ? '#dda749' : '#3cb46e'}`,
          }}
        ></i>
        {props.featured && <p>Closing Soon</p>}
        {!props.featured && <p>{props.topic.status}</p>}
      </div>

      <div className={classes.details}>
        <div className={classes.profiledetails}>
          <div>
            <h1 className={classes.title}>{props.topic.title}</h1>
            <h2>
              by {props.topic.owners[0].first_name}{' '}
              {props.topic.owners[0].last_name}
            </h2>
          </div>
          <img src={props.topic.owners[0].profile_image.medium} alt='profile' />
        </div>
        <p>{props.topic.description.substring(0, 88)}...</p>
      </div>
    </div>
  );
};

// title
// creator
// profile_image
// describe
// status
// total photos

export default TopicItem;
