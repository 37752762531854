import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import PhotoList from '../Layout/PhotoDisplay/PhotoList';
import classes from './TopicPageList.module.css';
import TopicSearchPage from '../../API/TopicSearchPage';
import LoadingSpinner from '../UI/LoadingSpinner';
import ModalContext from '../../context/ModalCotext';
import TopicData from '../../API/TopicData';

const TopicPageList = () => {
  const [topicImages, setTopicImages] = useState(null);
  const [coverPhoto, setCoverPhoto] = useState(null);
  const [topicData, setTopicData] = useState(null);
  const ctx = useContext(ModalContext);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get('id');

  useEffect(() => {
    const getTopicPhotos = async () => {
      ctx.showLoaderHandler(true);
      const topic = await TopicData(id);
      const photos = await TopicSearchPage(id);
      setTopicData(topic);
      setCoverPhoto(photos[0]);
      setTopicImages(photos.slice(1));
      // setTopicImages([topic, photos]);
    };
    getTopicPhotos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(topicData);

  return (
    <>
      {ctx.showLoader && <LoadingSpinner />}
      {coverPhoto && (
        <div className={classes.heading}>
          <img
            src={coverPhoto.urls.raw}
            alt='cover'
            className={classes.coverimage}
          />
          <div className={classes.details}>
            <h1>{topicData.title}</h1>
            <h3>
              by {topicData.owners[0].first_name}{' '}
              {topicData.owners[0].last_name}
            </h3>
            <p>{topicData.description}</p>
          </div>
        </div>
      )}
      {topicImages && (
        <PhotoList images={topicImages} loader={ctx.showLoaderHandler} />
      )}
    </>
  );
};

export default TopicPageList;
