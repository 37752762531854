import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import classes from './NavBar.module.css';
const NavBar = () => {
  const [showCross, setShowCross] = useState(false);
  const SearchBarInputRef = useRef();
  const history = useHistory();

  const handleClick = () => {
    SearchBarInputRef.current.focus();
    setShowCross(true);
  };

  const searchSubmitHandler = (event) => {
    if (event.key === 'Enter') {
      const search = SearchBarInputRef.current.value;
      history.push(`/search/photos?q=${search}`);
    }
  };

  const clearInputHandler = () => {
    SearchBarInputRef.current.value = '';
    SearchBarInputRef.current.blur();
    setShowCross(false);
  };

  return (
    <nav className={classes.navbar}>
      <NavLink to='/' activeClassName={classes.heading}>
        Image Gallery
      </NavLink>
      <div onClick={handleClick} className={classes.searchBar}>
        <i className={`fa-solid fa-magnifying-glass ${classes.Icon}`}></i>
        <input
          type='text'
          placeholder='Search for images'
          ref={SearchBarInputRef}
          onKeyDown={searchSubmitHandler}
        />
        {showCross && (
          <i
            onClick={clearInputHandler}
            className={`fa-solid fa-xmark ${classes.Icon}`}
          ></i>
        )}
      </div>
      <div className={classes.actionbuttons}>
        <NavLink to='/explore' activeClassName={classes.active}>
          Explore
        </NavLink>
        <NavLink to='/collection' activeClassName={classes.active}>
          Collection
        </NavLink>
        <NavLink to='/community' activeClassName={classes.active}>
          Community
        </NavLink>
      </div>
    </nav>
  );
};

export default NavBar;
