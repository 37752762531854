import './LoadingSpinner.css';

const LoadingSpinner = () => {
  console.log('Running');
  return (
    <div className='overlay'>
      <section className='sec-loading'>
        <div className='one'></div>
        <div className=''>Loading</div>
      </section>
    </div>
  );
};

export default LoadingSpinner;
