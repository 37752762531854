import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import SearchPageImage from '../../API/SearchPageImage';
import LoadingSpinner from '../UI/LoadingSpinner';
import PhotoList from '../Layout/PhotoDisplay/PhotoList';
import classes from './SearchPageList.module.css';

const SearchPageList = () => {
  const [images, setImages] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const searchItem = query.get('q');

  useEffect(() => {
    setShowLoader(true);
    const getImages = async () => {
      const imagelist = await SearchPageImage(searchItem);
      setImages(imagelist);
    };
    getImages();
  }, [searchItem]);

  return (
    <>
      <h1 className={classes.searchitem}>Search Results for {searchItem}</h1>
      <div className={classes.homephotos}>
        {showLoader && <LoadingSpinner />}
        {images && images.length > 0 && (
          <PhotoList loader={setShowLoader} images={images} />
        )}
      </div>
    </>
  );
};

export default SearchPageList;
