const SearchPageImage = async (searchitem) => {
  const key = process.env.REACT_APP_KEY;
  const url = `https://api.unsplash.com/search/photos/?client_id=${key}&query=${searchitem}&per_page=24`;

  const response = await fetch(url);
  const data = await response.json();
  const image = await data.results;
  return image;
};

export default SearchPageImage;
