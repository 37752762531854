import { useContext } from 'react';
import Modal from '../../UI/Modal';
import classes from './PhotoList.module.css';
import PhotoItem from './PhotoItem';
import ModalContext from '../../../context/ModalCotext';
import { useEffect } from 'react';

// const URL_LIST = {};

const PhotoList = (props) => {
  const ctx = useContext(ModalContext);
  let imageList = (
    <div className={classes.imagetile}>
      {props.images.map((image) => {
        return <PhotoItem img={image} key={image.id} />;
      })}
    </div>
  );

  return (
    <>
      {ctx.showModal && <Modal />}
      <div className={classes.imagetile}>{imageList}</div>
      {useEffect(() => {
        props.loader(false);
      }, [props])}
    </>
  );
};

export default PhotoList;
