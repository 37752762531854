import { useContext } from 'react';
import classes from './PhotoItem.module.css';
import ModalContext from '../../../context/ModalCotext';

const PhotoItem = (props) => {
  const ctx = useContext(ModalContext);

  const modalOpenHandler = () => {
    ctx.showLoaderHandler(true);
    ctx.modalDetails(props.img);
  };

  return (
    <>
      <div
        className={`${classes.card} ${ctx.showModal ? '' : classes.Item}`}
        onClick={modalOpenHandler}
      >
        <img
          src={`${props.img.urls.raw}&w=190&h=210&fit=crop&auto=format&dpr=2`}
          alt='Custom Pic'
          loading='lazy'
          className={classes.image}
        />

        <div className={classes.details}>
          <div className={classes.personaldetails}>
            <img src={props.img.user.profile_image.large} alt='Profile' />
            <div>
              <p className={classes.name}>
                {props.img.user.first_name} {props.img.user.last_name}
              </p>
              <p className={classes.username}>
                @{props.img.user.instagram_username}
              </p>
            </div>
          </div>
          <div className={classes.likes}>
            <i className={`fa-regular fa-thumbs-up ${classes.icon}`}></i>
            <p>{props.img.user.total_likes}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PhotoItem;
