import { useContext } from 'react';
import { useEffect } from 'react';
import './Modal.css';
import ModalContext from '../../context/ModalCotext';

const Modal = () => {
  const ctx = useContext(ModalContext);
  const img = ctx.modalItems;
  const imagetype = img.width > img.height;

  useEffect(() => {
    ctx.fullScreenHandler(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fullScreenHandler = () => {
    console.log(ctx.fullScreen);
    ctx.fullScreenHandler(!ctx.fullScreen);
  };

  const modalcloseHandler = () => {
    ctx.modalTrigger();
  };

  return (
    <>
      <section className='overlay'>
        <div className='modal'>
          <img
            src={`${img.urls.full}&width=1920&height=1080&fit=crop&auto=format&dpr=2`}
            alt='Modal'
            loading='lazy'
            className='image'
          />

          <div className='profile'>
            <div className='profile-details'>
              <img
                src={`${img.user.profile_image.medium}`}
                alt='Name'
                className='profileimage'
              />

              <div className='fullscreen' onClick={fullScreenHandler}>
                {!ctx.fullScreen && (
                  <i
                    className='fa-solid fa-expand fa-2xl'
                    onClick={fullScreenHandler}
                  ></i>
                )}
                {ctx.fullScreen && (
                  <i className='fa-solid fa-compress fa-2xl'></i>
                )}
              </div>

              <div>
                <h1 className='name'>
                  {img.user.first_name} {img.user.last_name}
                </h1>
                <p className='username'>@{img.user.username}</p>
              </div>
            </div>
            {img.user.social.instagram_username && (
              <div className='social'>
                <i
                  className='fa-brands fa-instagram fa-xl instagram'
                  style={{ color: 'rgba(0, 0, 0, 0.53)' }}
                ></i>
                <p className='username'>
                  /{img.user.social.instagram_username}
                </p>
              </div>
            )}
            {img.user.social.twitter_username && (
              <div className='social'>
                <i
                  className='fa-brands fa-twitter fa-xl instagram'
                  style={{
                    color: 'rgba(0, 0, 0, 0.53)',
                    'margin-right': '0rem',
                  }}
                ></i>
                <p className='username'>/{img.user.social.twitter_username}</p>
              </div>
            )}

            <div className='likes'>
              <i className='fa-regular fa-thumbs-up fa-xl icon'></i>
              <h2 className='name'>{img.likes}</h2>
            </div>
          </div>

          <button className='cross' onClick={modalcloseHandler}>
            x
          </button>
        </div>
      </section>

      {ctx.fullScreen && (
        <div className='overlay'>
          <div className='loading'></div>
          <img
            src={`${img.urls.small}fit=max&auto=format&dpr=2`}
            alt='full'
            className={`fullscreenimage ${
              imagetype ? 'fullscreenimagehori' : 'fullscreenimagevert'
            }`}
          />
          <button
            className={`cross ${
              imagetype ? 'crossfullscreenhori' : 'crossfullscreen'
            }`}
            onClick={fullScreenHandler}
          >
            x
          </button>
        </div>
      )}
    </>
  );
};

export default Modal;
