const TopicsPage = async () => {
  const key = process.env.REACT_APP_KEY;
  const url = `https://api.unsplash.com/topics/?client_id=${key}&per_page=20`;

  const response = await fetch(url);
  const data = await response.json();
  return data;
};

export default TopicsPage;
