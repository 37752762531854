import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import HomePageImage from '../../API/HomePageImage';
import LoadingSpinner from '../UI/LoadingSpinner';
import classes from './HomePageList.module.css';
import PhotoList from '../Layout/PhotoDisplay/PhotoList';
import { useContext } from 'react';
import ModalContext from '../../context/ModalCotext';

const HomePageList = () => {
  const [showCross, setShowCross] = useState(false);
  const [images, setImages] = useState([]);
  const SearchBarInputRef = useRef();
  const history = useHistory();

  const ctx = useContext(ModalContext);

  useEffect(() => {
    const getData = async () => {
      const imagesData = await HomePageImage();
      setImages(imagesData);
    };
    getData();
    SearchBarInputRef.current.value = '';
  }, []);

  const handleClick = () => {
    SearchBarInputRef.current.focus();
    setShowCross(true);
  };

  const searchSubmitHandler = (event) => {
    if (event.key === 'Enter') {
      const search = SearchBarInputRef.current.value;
      history.push(`/search/photos?q=${search}`);
    }
  };

  const clearInputHandler = () => {
    SearchBarInputRef.current.value = '';
    SearchBarInputRef.current.blur();
    setShowCross(false);
  };

  return (
    <>
      <div className={classes.mountain}>
        <div className={classes.container}>
          <h1 className={classes.heading}>
            Download High Quality Images By Creators
          </h1>
          <p className={classes.description}>
            Over 2.4 million stock Images by our talented community
          </p>

          <div onClick={handleClick} className={classes.searchBar}>
            <i className={`fa-solid fa-magnifying-glass ${classes.Icon}`}></i>
            <input
              type='text'
              placeholder='Search high resolution Images, categories, wallpapers'
              ref={SearchBarInputRef}
              onKeyDown={searchSubmitHandler}
            />
            {showCross && (
              <i
                onClick={clearInputHandler}
                className={`fa-solid fa-xmark ${classes.Icon} ${classes.cross}`}
              ></i>
            )}
          </div>
        </div>
      </div>
      {ctx.showLoader && <LoadingSpinner />}
      <div className={classes.homephotos}>
        {images.length > 0 && (
          <PhotoList images={images} loader={ctx.showLoaderHandler} />
        )}
      </div>
    </>
  );
};

export default HomePageList;
