import { useState } from 'react';
import ModalContext from './ModalCotext';

const ModalState = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [fullScreen, setFullScreen] = useState(false);
  const [modalItems, setModalItems] = useState({});

  const modalDetails = (items) => {
    setModalItems({ ...items });
    modalTrigger();
  };

  const fullScreenHandler = (boolstate) => {
    setFullScreen(boolstate);
  };

  const showLoaderHandler = (boolstate) => {
    setShowLoader(boolstate);
  };

  const modalTrigger = () => {
    setShowModal((prevState) => !prevState);
  };

  return (
    <ModalContext.Provider
      value={{
        showModal,
        modalTrigger,
        modalDetails,
        modalItems,
        showLoader,
        showLoaderHandler,
        fullScreen,
        fullScreenHandler,
      }}
    >
      {props.children}
    </ModalContext.Provider>
  );
};

export default ModalState;
