const TopicData = async (id) => {
  const key = process.env.REACT_APP_KEY;
  const url = `https://api.unsplash.com/topics/${id}/?client_id=${key}`;

  const response = await fetch(url);
  const data = await response.json();
  return data;
};

export default TopicData;
