const TopicSearchPage = async (id) => {
  const key = process.env.REACT_APP_KEY;
  const url = `https://api.unsplash.com/topics/${id}/photos/?client_id=${key}`;

  const response = await fetch(url + '&per_page=21');
  const data = await response.json();

  const res = await fetch(url + '&orientation=landscape&per_page=1');
  const coverphoto = await res.json();
  const d = [coverphoto[0], ...data];
  return d;
};

export default TopicSearchPage;
