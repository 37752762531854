import { Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import SearchPage from './pages/SearchPage';
import NavBar from './components/Layout/NavBar';
import { Switch } from 'react-router-dom/cjs/react-router-dom.min';
import ExplorePage from './pages/ExplorePage';
import TopicPage from './pages/TopicPage';
// import AuthForm from './components/auth/AuthForm';

function App() {
  return (
    <>
      <NavBar />
      <Switch>
        <Route path='/' exact>
          <HomePage />
        </Route>
        <Route path='/search/photos' exact>
          <SearchPage />
        </Route>
        <Route path='/explore' exact>
          <ExplorePage />
        </Route>
        <Route path='/explore/:id'>
          <TopicPage />
        </Route>
      </Switch>
    </>
  );
}

export default App;
