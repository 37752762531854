import { useEffect, useState } from 'react';
import classes from './ExplorePageList.module.css';
import TopicsPage from '../../API/TopicsPage';
import TopicList from '../Layout/TopicsDisplay/TopicList';
import TopicItem from '../Layout/TopicsDisplay/TopicItem';

const ExplorePageList = () => {
  const [topics, setTopics] = useState([]);
  const [featured, setFeatured] = useState(null);
  useEffect(() => {
    const getAllTopics = async () => {
      const getTopics = await TopicsPage();
      setTopics(await getTopics.slice(1));
      setFeatured(await getTopics[0]);
    };
    getAllTopics();
  }, []);

  return (
    <>
      <div className={classes.heading}>
        <h1>Topics</h1>
        <p>Explore the world through topics of beautiful photos</p>
      </div>
      <div className={classes.featured}>
        <h2>Featured</h2>
        {featured && <TopicItem topic={featured} featured={true} />}
      </div>
      <div className={classes.topicheading}>
        <h2>All Topics</h2>
        <TopicList topics={topics} />
      </div>
    </>
  );
};

export default ExplorePageList;
