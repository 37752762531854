const HomePageList = async () => {
  const page = parseInt(Math.random() * (6000 - 1) + 1);
  const key = process.env.REACT_APP_KEY;

  const url = `https://api.unsplash.com/photos/?client_id=${key}&per_page=32&page=${page}`;
  const response = await fetch(url);

  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else {
    const k1 = process.env.REACT_APP_KEY1;
    const url1 = `https://api.unsplash.com/photos/?client_id=${k1}&per_page=32&page=${page}`;
    const response = await fetch(url1);
    const data = await response.json();
    return data;
  }
};

export default HomePageList;
