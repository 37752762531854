import TopicItem from './TopicItem';

import classes from './TopicList.module.css';
const TopicList = (props) => {
  return (
    <div className={classes.topics}>
      {props.topics.map((topic) => {
        return <TopicItem topic={topic} key={topic.id} featured={false} />;
      })}
    </div>
  );
};

export default TopicList;
